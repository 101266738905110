import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️nternet-age-institutions",
      "style": {
        "position": "relative"
      }
    }}>{`ℹ️nternet age institutions`}<a parentName="h1" {...{
        "href": "#%EF%B8%8Fnternet-age-institutions",
        "aria-label": "️nternet age institutions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Much like we first introduced `}<a parentName="p" {...{
        "href": "/learn/module-1/"
      }}>{`the subversive joy of Ethereum`}</a>{` and then took a step back to build a deeper understanding of `}<a parentName="p" {...{
        "href": "/learn/module-2/"
      }}>{`the current financial system`}</a>{`; now that we've looked at what it might mean to `}<a parentName="p" {...{
        "href": "/learn/module-3/"
      }}>{`reclaim the web`}</a>{` as a creative and collaborative commons, we must go back and understand more clearly how we wish to go on instituting this world wide web of light. `}</p>
    <blockquote>
      <p parentName="blockquote">{`"Fundamental social change must begin with a change of consciousness about institutions `}{`[...]`}{` a viable future turns on the rejuvenation of institutional style `}{`[...]`}{` At stake in the choice between institutions is the very nature of human life. We must choose whether to be rich in things or in the freedom to use them. The choice between sad unemployment and joyful leisure is now open for the entire culture `}{`[...]`}{` The radically alternative way to fill available time is a limited range of more durable goods and providing access to institutions which can `}<strong parentName="p">{`increase the opportunity and desirability of human interaction.`}</strong>{`" -- Ivan Illich, `}<em parentName="p">{`Deschooling Society`}</em></p>
    </blockquote>
    <p>{`Now that you have taken back your ability to participate in our shared web by cultivating independent thought and thereby reclaiming your time, we can look together at the kinds of convivial institutions which might extend our ability to cooperate and live well together. `}</p>
    <p>{`To begin our exploration, we once more turn to what is always at the heart of Kernel: `}<strong parentName="p">{`relationship`}</strong>{`. We are here to increase the opportunity and desirability of human interaction; to facilitate not just coordination (shared system) and collaboration (shared values), but full and loving cooperation (shared purpose). The outcome can only be celebration (shared aliveness).     `}</p>
    <p>{`Our focus on relationship and `}<a parentName="p" {...{
        "href": "/conversation/reciprocity"
      }}>{`reciprocity`}</a>{` reflects an understanding that we are not here to burn down the current systems: such acts would participate in–and thus reinforce–the coercive and violent structures presupposed by those very institutions. Instead, we will try to understand what a principled refusal of tools and institutions which inculcate dependency might look like. We'll ask what it means to learn, or heal, or create, or move of our own accord, and how we can create networked institutions premised on spontaneous, voluntary use rather than coercive force.`}</p>
    <p>{`There is an old story known as `}<Link to="https://fs.blog/2020/03/chestertons-fence/" mdxType="Link">{`Chesterton's Fence`}</Link>{` which has many versions, the most popular of which involves an Englishman kicking down the closed gate of a field he is walking through only to be trampled by the bull that gate was keeping enclosed. The moral is: don't break down barriers the function of which you do not fully understand. Genuine insight–rather than contemptuous dismissal of the status quo–engenders love, and it is love - not violence - which is `}<a parentName="p" {...{
        "href": "https://read.gov/aesop/143.html"
      }}>{`the force capable of creating our social realities anew`}</a>{`.`}</p>
    <h2 {...{
      "id": "convivial-customs",
      "style": {
        "position": "relative"
      }
    }}>{`Convivial Customs`}<a parentName="h2" {...{
        "href": "#convivial-customs",
        "aria-label": "convivial customs permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Institutions need not be physical entities such as The Federal Reserve, or a government department, or a place you go when you need to spend some time separate from society. We also have—for instance—the institution of marriage, which is a `}<strong parentName="p">{`practice`}</strong>{` or a `}<strong parentName="p">{`custom`}</strong>{`. It is in this sense we will be using the word for the rest of Chapter 4.`}</p>
    <p>{`We will draw on `}<a parentName="p" {...{
        "href": "https://medium.com/berkman-klein-center/an-introduction-to-extitutional-theory-e74b5a49ea53"
      }}>{`extitutional theory`}</a>{`, which contrasts enclosure with the concept of `}<strong parentName="p">{`exclosure`}</strong>{`, recognizing that certain structures are needed to protect the activity within it from outside control. Understanding the practices and social customs that support extitutional dynamics, and their interplay with more familiar tools and logics of institutions, can help us respond to our era’s unprecedented demands on human coordination.`}</p>
    <p>{`Trying to route information around slow-moving bureaucracies requires us to develop a deeper understanding of the many ways we can relate to one another and `}<strong parentName="p">{`organize ourselves`}</strong>{`. In particular, the internet has caused - and will continue to cause - critical shifts in three spheres of human life: how we `}<em parentName="p">{`identify ourselves`}</em>{`, how we `}<em parentName="p">{`reach consensus`}</em>{`, and how we `}<em parentName="p">{`experience time`}</em>{`. Cast in the language of anarchy - which means no rulers, not no rules - institution as practice and custom, rather than a static set of rules, is closely related to what David Graeber calls `}<Link to="https://abahlali.org/files/Graeber.pdf" mdxType="Link">{`"counterpower"`}</Link>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Institutionally, counterpower takes the form of what we would call institutions of direct democracy, consensus and mediation; that is, ways of publicly negotiating and controlling that inevitable internal tumult and transforming it into social states (or if you like, forms of value) that society sees as the most desirable: conviviality, unanimity, fertility, prosperity, beauty, however it may be framed.`}</p>
    </blockquote>
    <p>{`In particular, we will make the claim this week that we can indeed `}<a parentName="p" {...{
        "href": "https://www.buildingbeauty.org/beautiful-software"
      }}>{`build for beauty`}</a>{`. We can co-create patterns which lead each one of us closer to the unhindered perception of what Christopher Alexander calls "true Self". In another classic interplay of complementary opposites, pattern languages which cultivate such intimately personal perception are inherently communal and, as such, lead us back to custom and conviviality. In concert with Alexander, we can turn back to Ivan Illich for an illuminating perspective regarding `}<strong parentName="p">{`commons`}</strong>{` and `}<strong parentName="p">{`custom`}</strong>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The concept of the commons is not that of a resource; a commons comes from a totally different way of being in the world where it is not production which counts, but bodily, physical use according to rules that are established by custom, which never recognizes equality of all subjects because different people follow different customs. `}<strong parentName="p">{`Their differences can be recognized in the way they share the commons.`}</strong></p>
    </blockquote>
    <h2 {...{
      "id": "week-4-firesides",
      "style": {
        "position": "relative"
      }
    }}>{`Week 4 Firesides`}<a parentName="h2" {...{
        "href": "#week-4-firesides",
        "aria-label": "week 4 firesides permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Kei Kreutler & Aaron Lewis - October 14, 2022`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/koknoQSERkE" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Yancey Strickler - October 28, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/WAJaE0Uy6qE?start=275" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Andy Tudhope - June 17, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/ijmmKr-ZjsU" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Mariano Conti - Feb 11, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/dtNvB6D2Emw?start=175" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Dandelion Mane - August 6 2020`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/djQhzNA9bzc?start=938" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      